import moment from "moment";

/**
 * Converts a given date to a Moment.js object.
 *
 * @param {Date} dateWithoutTime - The date object to be converted, which should not include time.
 * @returns {moment.Moment} A Moment.js object representing the provided date.

 */
export const convertSelectedDateToMoment = (dateWithoutTime: Date): moment.Moment =>
  // if (
  //   !(dateWithoutTime instanceof Date) ||
  //   Number.isNaN(dateWithoutTime.getTime())
  // ) {
  //   throw new TypeError("Invalid date provided.");
  // }

  moment(dateWithoutTime);

// * @throws {TypeError} Throws an error if the input is not a valid Date object.
