import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { store } from "./store/store";

const container = document.getElementById("root");
const root = createRoot(container as Element);

/*
https://evvve.atlassian.net/browse/PROD-4223
It worked incorrect.
If it will be deleted, should delete it in package.json too.

Sentry.init({
  dsn: "https://1644bf532b104e308307c004c2c3e522@o4504061756571648.ingest.sentry.io/4504061757489152",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
*/

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// To log results (for example: reportWebVitals(console.log))
// Or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
