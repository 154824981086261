/* eslint-disable implicit-arrow-linebreak */
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { Icons, Text, Input } from "@evvve/ui-kit";
import { messengerApi } from "src/store/messenger";
import s from "./style.module.scss";
import { useMessengerViewController } from "../../hooks";
import { MessengerViewVariant } from "../../types";
import { ParticipantItem } from "./ui";

const ParticipantListView: FC = () => {
  const { goToView, chatId } = useMessengerViewController();
  const [getChatParticipants, { data: participants }] =
    messengerApi.useLazyGetChatParticipantsQuery();

  const [searchValue, setSearchValue] = useState("");

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value.toLowerCase());
  };

  const onGoBackToPaticipants = () => {
    if (!chatId) throw new Error("[CHAT]: There is not chatId");

    goToView(MessengerViewVariant.CHAT_BY_ID, { chatId });
  };

  useEffect(() => {
    if (!chatId) throw new Error("[CHAT]: There is not chatId");

    getChatParticipants({ chatId });
  }, [chatId, getChatParticipants]);

  const filteredParticipants = useMemo(() => {
    if (!participants) return [];

    if (searchValue === "") return participants;

    return participants.filter(
      (participant) =>
        participant && participant.data.name.toLowerCase().includes(searchValue),
    );
  }, [participants, searchValue]);

  return (
    <>
      <div className={s.header}>
        <div className={s.backButtonAndTitle}>
          <button
            onClick={onGoBackToPaticipants}
            className={s.backButton}
            aria-labelledby="TODO:"
            type="button"
          >
            <Icons.ChevronLeft size="m" color="grayscale600" />
          </button>
          <Text
            size={["m", "m", "m", "m", 6]}
            fontWeight={600}
            className={s.participantsTitle}
          >
            Participants
          </Text>
        </div>
      </div>
      <div className={s.search}>
        <div className={s.search_input}>
          <Input
            onChange={onSearchValueChange}
            value={searchValue}
            endAdornment={<Icons.Search color="grayscale400" size="m" />}
            placeholder="Search by name"
          />
        </div>
      </div>
      <ul className={s.participantsList}>
        {filteredParticipants.map((participant) => (
          <ParticipantItem
            key={participant.id}
            avatarUrl={participant.data.user_avatar}
            fullName={participant.data.name}
            id={participant.id}
          />
        ))}
      </ul>
    </>
  );
};

export default ParticipantListView;
