import { ONE_MINUTE_IM_MS } from "../const";

/**
 * Calculates the time difference between two timestamps in minutes.
 *
 * @param {number} start - The start timestamp in milliseconds.
 * @param {number} finish - The finish timestamp in milliseconds.
 * @returns {number} The time difference in minutes.
 * @throws {Error} If either `start` or `finish` is not a number.
 */
export const calcMinutes = (start: number, finish: number): number => {
  if (typeof start !== "number" || typeof finish !== "number") {
    throw new Error("Both timestamps must be numbers.");
  }

  return (finish - start) / ONE_MINUTE_IM_MS;
};
