// FIX_ME:
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { chatApi } from "src/api/chatApi";
import { appApi } from "src/api/api";
import {
  GetChatInfoQuery,
  GetChatListResp,
  GetChatListQuery,
  ChatDTO,
  GetMessageListResp,
  GetMessageListQuery,
  GetInitialMessegesResp,
  ChatMemberDTO,
  GetChatMembersResp,
  GetChatMembersQuery,
  DeleteChatQuery,
  GetMessagesHistoryQuery,
  GetChatListTransformedRes,
  GetMessageListTransformedRes,
  GetMessagesHistoryTransformedRes,
  SendNewMessageData,
} from "./types";
import {
  onChatUpdateHandler,
  onDeleteDialogHandler,
  onNewDialogHandler,
  onReceiveNewMessageHandler,
  onUserUpdateHandler,
} from "./socket/socket-handlers";
import {
  ChatAuthOptions,
  getSocketWithAuth,
  socketEmitAsPromise,
} from "./socket/createSocketFactory";
import { RootState } from "../store";
import {
  generateNewMessage,
  getCurrentUser,
  removeChatOnQueryStarted,
  sortMessagsByDatetime,
  updateMessageListOnReceiveNewMessage,
} from "./lib";
import { ChatEventAction, ServiceSocketEvents } from "./socket/types";
import {
  updateChatListOnReadLastMsg,
  updateMessageListOnReadLastMsg,
} from "./lib/optimistic-updates";

type ReturnEventGroupChatQuery = {
  eventId: string;
};

const baseMessengerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getChatAuthToken: builder.query<ChatAuthOptions, void>({
      query: () => ({
        method: "POST",
        url: "/chat/request-token",
      }),
    }),
    initDialog: builder.query({
      query: ({ userId }) => ({
        method: "POST",
        url: "/chat/init-dialog",
        body: {
          recipient_id: userId,
        },
      }),
    }),
  }),
});

const messengerApi = chatApi.injectEndpoints({
  endpoints: (builder) => ({
    getChatList: builder.query<GetChatListTransformedRes, GetChatListQuery>({
      query: ({ lastEvaluatedKey }) => ({
        method: "POST",
        url: "/dialogs/get-dialogs",
        body: { last_evaluated_key: lastEvaluatedKey },
      }),
      transformResponse: (response: GetChatListResp) => ({
        data: response.dialogs,
        lastEvaluatedKey: response.last_evaluated_key,
      }),
      providesTags: [{ type: "ChatList" }],
    }),

    getChatInfoById: builder.query<ChatDTO, GetChatInfoQuery>({
      query: ({ chatId }) => ({
        url: "/dialogs/get-dialog-by-chat-id",
        params: { chat_id: chatId },
      }),
      providesTags: (_, __, requestArgs) => [
        { type: "Chat", id: requestArgs.chatId },
      ],
    }),

    getChatInfoByUser: builder.query({
      query: ({ userId }) => ({
        url: "/dialogs/get-dialog-by-recipient",
        params: { recipient_id: userId },
      }),
    }),

    // NOTE: отдает певую пачку последних сообщений
    getMessagesHistory: builder.query<
      GetMessagesHistoryTransformedRes,
      GetMessagesHistoryQuery
    >({
      query: ({ chatId }) => ({
        method: "POST",
        url: "/messages/init-messages-history",
        body: {
          chat_id: chatId,
        },
      }),
      transformResponse: (response: GetInitialMessegesResp) => {
        const readMessages = response.oldest?.messages || [];
        const unreadMessages = (response.newest?.messages || []).map((message) => ({
          ...message,
          isUnread: true,
        }));

        const lastEvaluatedKeyRead = response.oldest?.last_evaluated_key;
        const lastEvaluatedKeyUnread = response.newest?.last_evaluated_key;

        return {
          data: [...readMessages, ...unreadMessages],
          lastEvaluatedKey: lastEvaluatedKeyRead || lastEvaluatedKeyUnread,
        };
      },
      providesTags: (_, __, requestArgs) => [
        { type: "MessageList", id: requestArgs.chatId },
      ],
    }),

    // NOTE: отдает следующие пачки сообщений по 50(?), но для этого необходимо передавать lastEvaluatedKey
    // тут нужно получать данные и засовывать их в начало getMessagesHistory чтобы получать их можно было
    // не из разных мест а из одного
    getMessageList: builder.query<GetMessageListTransformedRes, GetMessageListQuery>(
      {
        query: ({ chatId, lastEvaluatedKey, reverse }) => ({
          method: "POST",
          url: "/messages/get-messages",
          body: { chat_id: chatId, last_evaluated_key: lastEvaluatedKey, reverse },
        }),
        transformResponse: (res: GetMessageListResp) => ({
          data: sortMessagsByDatetime(res.messages),
          lastEvaluatedKey: res.last_evaluated_key,
        }),
        onCacheEntryAdded: async (
          requestData,
          { dispatch, cacheDataLoaded, ...args },
        ) => {
          // NOTE: Паш, в этом месте видимо будем вместе разбираться, как лучше сделать эту подгрузку
          console.log("args", args);

          try {
            const { data: messageListData } = await cacheDataLoaded;

            const nextPartOfMessages = messageListData.data;
            const nextLastEvaluatedKey = messageListData.lastEvaluatedKey;

            // export const updateMessageListOnReceiveNewMessage = ({ chatId, newMessage }: Args) =>
            //   messengerApi.util.updateQueryData("getMessagesHistory", { chatId }, (draft) => {
            //     draft.data.push(newMessage);
            //   });

            dispatch(
              messengerApi.util.updateQueryData(
                "getMessagesHistory",
                { chatId: requestData.chatId },
                (previousPartOfMessagesDraft) => {
                  previousPartOfMessagesDraft.lastEvaluatedKey =
                    nextLastEvaluatedKey;
                  previousPartOfMessagesDraft.data = [
                    ...nextPartOfMessages,
                    ...previousPartOfMessagesDraft.data,
                  ];
                },
              ),
            );
          } catch (error) {
            // [CHAT] TODO: обработать ошибку
          }
        },
      },
    ),

    getChatParticipants: builder.query<ChatMemberDTO[], GetChatMembersQuery>({
      query: ({ chatId }) => ({
        url: `/chat/${chatId}/members`,
      }),
      transformResponse: (response: GetChatMembersResp): ChatMemberDTO[] =>
        response.members,
    }),

    // NOTE: для персональных чатов
    deleteChat: builder.mutation<void, DeleteChatQuery>({
      query: ({ chatId }) => ({
        method: "POST",
        url: "/dialogs/delete-dialog",
        body: { chat_id: chatId },
      }),
      invalidatesTags: [{ type: "ChatList" }],
      // FIX_ME:
      // @ts-ignore
      onQueryStarted: removeChatOnQueryStarted,
    }),

    // NOTE: для групповых чатов
    leaveChat: builder.mutation<void, DeleteChatQuery>({
      query: ({ chatId }) => ({
        method: "POST",
        url: `/chat/${chatId}/leave`,
      }),
      invalidatesTags: [{ type: "ChatList" }],
      // FIX_ME:
      // @ts-ignore
      onQueryStarted: removeChatOnQueryStarted,
    }),

    // NOTE: каждый раз когда заходим в event space возвращаем юзера в диалог даже если он до этого удалился
    forceReturnToGroupChat: builder.mutation<void, ReturnEventGroupChatQuery>({
      query: ({ eventId }) => ({
        method: "POST",
        url: "/dialogs/return-dialog",
        body: { chat_id: eventId },
      }),
      invalidatesTags: [{ type: "ChatList" }],
    }),

    subscribeToEvents: builder.query({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(
        _,
        { dispatch, cacheEntryRemoved, cacheDataLoaded, getState },
      ) {
        const state = getState() as RootState;

        const socket = await getSocketWithAuth();

        try {
          await cacheDataLoaded;

          socket.on(
            ChatEventAction.NEW_MESSAGE,
            onReceiveNewMessageHandler({ dispatch, state }),
          );

          socket.on(ChatEventAction.CHAT_UPDATE, onChatUpdateHandler({ dispatch }));
          socket.on(
            ChatEventAction.DELETE_DIALOG,
            onDeleteDialogHandler({ dispatch }),
          );
          socket.on(
            ChatEventAction.NEW_DIALOG,
            onNewDialogHandler({ state, dispatch }),
          );
          socket.on(ChatEventAction.USER_UPDATE, onUserUpdateHandler({ dispatch }));

          socket.on(ServiceSocketEvents.DISCONNECT, () => {
            socket.disconnect();
          });

          socket.on("errors", (data) => {
            // TODO: обработать ошибки сокета
            console.log("errors", data);
          });
        } catch (error) {
          console.error("subscribeToEvents/onCacheEntryAdded", error);
        }

        await cacheEntryRemoved;
        socket.close();
      },
    }),

    sendLastReadMessage: builder.mutation({
      queryFn: async (data) => {
        const socket = await getSocketWithAuth();

        return socketEmitAsPromise(socket)(ChatEventAction.LAST_READ_MESSAGE, data);
      },
      onQueryStarted: async ({ chatId }, { queryFulfilled, dispatch }) => {
        const lastEvaluatedKey = null;

        const patchChatList = dispatch(
          updateChatListOnReadLastMsg({
            chatId,
            lastEvaluatedKey,
          }),
        );

        const patchMessageList = dispatch(
          updateMessageListOnReadLastMsg({ chatId }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchChatList.undo();
          patchMessageList.undo();
        }
      },
      invalidatesTags: (_, __, { chatId }) => [
        { type: "MessageList", id: chatId },
        { type: "ChatList" },
      ],
    }),

    sendMessage: builder.mutation<void, SendNewMessageData>({
      // FiX_ME:
      // @ts-ignore
      queryFn: async (data) => {
        const socket = await getSocketWithAuth();

        return socketEmitAsPromise(socket)(ChatEventAction.SEND_MESSAGE, data);
      },
      invalidatesTags: (_, __, body) => [
        { type: "MessageList", id: body.chat_id },
        { type: "ChatList" },
      ],
      onQueryStarted: async (body, { dispatch, getState, queryFulfilled }) => {
        const state = getState() as RootState;
        const currentUser = await getCurrentUser(state, dispatch);
        const chatId = body.chat_id;
        const newMessage = generateNewMessage({ ...body, currentUser });

        const patchResult = dispatch(
          updateMessageListOnReceiveNewMessage({ chatId, newMessage }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    // [CHAT] TODO: от этой ручки надо отказываться, выглядит бессмысленной
    // NOTE: пока посмотрим как все работает без нее и можно ли без нее обходиться
    // getIfHasUnreadMessages: builder.query<{ unread_messages: boolean }, void>({
    //   query: () => ({
    //     url: "/messages/unread-messages",
    //   }),
    // }),
    // NOTE: так и не выяснили с Данилой зачем этот эмиттер нужен
    // будем выяснять в процессе тестирования
    // chooseChat: builder.mutation({
    //   queryFn: async (data, { dispatch }) => {
    //     const socket = await getSocketWithAuth(dispatch);

    //     return socketEmitAsPromise(socket)(ChatEventAction.CHOOSE_CHAT, data);
    //   },
    // }),

    // NOTE: посмотрели с Данилой и пока похоже что этот эмиттер не нужен
    // addRoom: builder.mutation({
    //   queryFn: async (data, { dispatch }) => {
    //     const socket = await getSocketWithAuth(dispatch);

    //     return socketEmitAsPromise(socket)(ChatEventAction.ADD_ROOM, data);
    //   },
    // }),
  }),
});

export { baseMessengerApi, messengerApi };
