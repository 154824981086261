import React, { PropsWithChildren, useEffect, useState } from "react";
import { Toast2 } from "@evvve/ui-kit";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { removeToast } from "src/store/toast/slice";
import { useInfoModals } from "../../hooks/useInfoModals";

export const ToastContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const currentToast = useAppSelector((state) => state.toast.toasts[0]);
  const dispatch = useAppDispatch();
  const infoModals = useInfoModals();
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    if (currentToast) {
      const timeout = setTimeout(
        () => setIsToastOpen(false),
        currentToast?.estimate,
      );

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [currentToast, dispatch]);

  useEffect(() => {
    if (isToastOpen === false && currentToast) {
      const timeout = setTimeout(
        () => dispatch(removeToast(currentToast?.id)),
        1000,
      );

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [currentToast, dispatch, isToastOpen]);

  useEffect(() => {
    if (currentToast) setIsToastOpen(true);
  }, [currentToast]);

  return (
    <>
      {children}
      {infoModals}
      <Toast2
        key={currentToast?.id}
        type={currentToast?.type}
        onClose={() => setIsToastOpen(false)}
        setOpen={setIsToastOpen}
        open={isToastOpen}
        position="bottom"
        autoHideDuration={currentToast?.estimate}
      >
        {currentToast?.content}
      </Toast2>
    </>
  );
};
