export const EVENT_STATUS_LIVE = "live";
export const EVENT_STATUS_PAST = "past";
export const EVENT_STATUS_DRAFT = "draft";
export const EVENT_STATUS_UPCOMING = "upcoming";
export const EVENT_STATUS_PRE_LIVE = "pre_live";

export const EVENT_ROLE_ORGANIZER = "organizer";
export const EVENT_ROLE_SPEAKER = "speaker";
export const EVENT_ROLE_ATTENDEE = "attendee";

export const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;
export const CHAT_API_BASE_URL = process.env.REACT_APP_CHAT_API_BASE_URL;
export const CHAT_WEB_SOCKET_URL = process.env.REACT_APP_CHAT_WEB_SOCKET_URL;
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE;
export const SITE_BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

export const STREAMING_WSS_URL = process.env.REACT_APP_STREAMING_SOCKET_URL;

export const IS_DEVELOPMENT_MODE = process.env.REACT_APP_NODE_ENV === "dev";

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/g;
// min 8 characters, 1 number and 1 character

export enum InputMaxLength {
  S_24 = 24,
  M_128 = 128,
  L_256 = 256,
  XL_512 = 512,
  XXL_1024 = 1024,
  XXXL_4096 = 4096,
}

export enum Spacing {
  XXXXS = 8,
  XXXS = 16,
  XXS = 20,
  XS = 24,
  S = 32,
  M = 40,
  L = 48,
  X = 103,
  XXL = 116,
  XXXL = 120,
  XXXXL = 156,
}

export const IMG_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
  // NOTE [PROD-1093]: temporarily unsupported types
  // "image/heic",
  // "image/heif",
];
export const IMG_EXTENSIONS_FOR_WINDOWS = [".jpeg", ".jpg", ".png"];

export const MAX_FILE_SIZE = 10 * 1024 ** 2;

export const deleteDialogStatics = {
  title: "Delete this dialogue?",
  caption:
    "You are going to delete your copy of the conversation with the following person.",
};

export const leaveGroupStatics = {
  title: "Leave this group?",
  caption:
    "You are going to leave and delete the group from your Chat. " +
    "It will be added back to your list of dialogues and groups if you enter the event space again.",
};

export const DEFAULT_NOTIFICATION_ESTIMATE = 3000;

// Metrics
export const LIVE_METRICS_TRACK_INTERVAL = 15_000;
