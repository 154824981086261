import type { DependencyList } from "react";
import { useEffect, useRef } from "react";

type Effect<T extends DependencyList> = (
  changes?: number[],
  previousDeps?: T,
  currentDeps?: T,
) => void | (() => void);

const diffTwoDeps = (deps1?: DependencyList, deps2?: DependencyList) => {
  // Let's do a reference equality check on 2 dependency list.
  // If deps1 is defined, we iterate over deps1 and do comparison on each element with equivalent element from deps2
  // As this func is used only in this hook, we assume 2 deps always have same length.

  if (deps1) {
    return deps1
      .map((_ele, idx) => (!Object.is(deps1[idx], deps2?.[idx]) ? idx : -1))
      .filter((ele) => ele >= 0);
  }

  if (deps2) {
    return deps2.map((_ele, idx) => idx);
  }

  return [];
};

const useTrackedEffect = <T extends DependencyList>(
  effect: Effect<T>,
  deps?: [...T],
) => {
  const previousDepsRef = useRef<T>();

  useEffect(() => {
    const changes = diffTwoDeps(previousDepsRef.current, deps);
    const previousDeps = previousDepsRef.current;
    previousDepsRef.current = deps;

    return effect(changes, previousDeps, deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useTrackedEffect;
