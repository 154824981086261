import { Moment } from "moment";

/**
 * Converts a Moment.js object to a native JavaScript Date object.
 * If no Moment object is provided, it returns undefined.
 *
 * @param {Moment} [moment] - The Moment.js object to be converted. Optional.
 * @returns {Date|undefined} - The corresponding Date object, or undefined if no Moment object is provided.
 */
export const convertMomentToDate = (moment?: Moment): Date | undefined => {
  if (!moment) return undefined;

  return moment.clone().toDate();
};
