import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { addToast } from "../toast/slice";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../constants";
// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { store } from "../store";

export const rtkQueryErrorHandler: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const error = action.payload;
    const originalStatus = error.originalStatus ?? error.status;
    if (originalStatus >= 500) {
      store.dispatch(
        addToast({
          type: "error",
          content: "Something went wrong. Please try later",
          estimate: DEFAULT_NOTIFICATION_ESTIMATE,
        }),
      );
    }
  }

  return next(action);
};
