import { useCallback, useState } from "react";
import { Button } from "@evvve/ui-kit";
import UserSummary from "src/close-part/features/User/UserSummary";
import { getFullName } from "src/store/events/helpers";
import { UserShort } from "src/store/events/types";
import { GetUserResponse } from "src/store/User/types";
import { useFollowOrUnfollowUserMutation } from "src/store/User/api";
import {
  updateCachedUserCardInChat,
  updateFollowingCountInProfile,
  updateUserCardInChat,
} from "src/store/User/followOrUnfollow";
import style from "./follow.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import Link from "../../Link";

interface FollowListItemProps {
  userInfo: GetUserResponse;
  onFollowOrUnfollowButtonClick: () => void;
}

export const FollowListItem = ({
  userInfo,
  onFollowOrUnfollowButtonClick,
}: FollowListItemProps) => {
  const [doIFollowUser, setDoIFollowUser] = useState<boolean>(userInfo.is_following);

  const currentUser = useAppSelector((state) => state.user.userInfo);

  const [followOrUnfollow, { isLoading: isFollowOrUnfollowProcessing }] =
    useFollowOrUnfollowUserMutation();

  const dispatch = useAppDispatch();

  const onToggleSubscription = useCallback(async () => {
    if (!userInfo || !currentUser) return;

    const userId = userInfo.user.unique_id;

    try {
      const updatedFollowedOrUnfollowedUser = await followOrUnfollow({
        doIFollowUser,
        userId,
      }).unwrap();
      setDoIFollowUser(updatedFollowedOrUnfollowedUser.is_following);

      onFollowOrUnfollowButtonClick();

      dispatch(
        updateFollowingCountInProfile(
          currentUser,
          doIFollowUser ? "unfollow" : "follow",
        ),
      );
      dispatch(updateCachedUserCardInChat(updatedFollowedOrUnfollowedUser));
      dispatch(updateUserCardInChat(currentUser.unique_id));
    } catch {
      //
    }
  }, [
    userInfo,
    currentUser,
    doIFollowUser,
    dispatch,
    followOrUnfollow,
    onFollowOrUnfollowButtonClick,
  ]);

  return (
    <div className={style.followListItem}>
      <div className={style.content}>
        <Link
          to={`/profile/${userInfo.user.username}`}
          keepSearchParams
          className={style.link}
        >
          <UserSummary
            avatar={userInfo.user.avatar_url}
            name={getFullName(userInfo.user as UserShort)}
            company={userInfo.user.company}
            companyOrUsernameClassname={style.company}
            className={style.userSummary}
          />
        </Link>
      </div>
      <Button
        size="xs"
        type="outline"
        onClick={onToggleSubscription}
        isLoading={isFollowOrUnfollowProcessing}
      >
        {doIFollowUser ? "Unfollow" : "Follow"}
      </Button>
    </div>
  );
};
