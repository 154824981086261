import { Moment } from "moment";

/**
 * Replaces the year, month, and date of the given Moment object with those from the targetDate.
 *
 * @param {Moment} val - The original Moment object.
 * @param {Moment} targetDate - The Moment object providing the new date values.
 * @returns {Moment} A new Moment object with the date replaced.
 */
export const replaceDate = (val: Moment, targetDate: Moment): Moment =>
  // if (!moment.isMoment(val) || !val.isValid()) {
  //   throw new TypeError('Invalid Moment object provided for "val".');
  // }
  // if (!moment.isMoment(targetDate) || !targetDate.isValid()) {
  //   throw new TypeError('Invalid Moment object provided for "targetDate".');
  // }

  val
    .clone()
    .year(targetDate.year())
    .month(targetDate.month())
    .date(targetDate.date());

// * @throws {TypeError} Throws an error if either argument is not a valid Moment object.
