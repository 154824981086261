import classNames from "classnames";
import React from "react";
import { Icons, Text, Tooltip } from "@evvve/ui-kit";
import styles from "./style.module.scss";

export interface InputGroupTitleProps {
  title: string;
  tooltip?: string;
  isOptional?: boolean;
  wrapClassName?: string;
  labelClassName?: string;
}

export const InputGroupTitle = ({
  title,
  tooltip,
  isOptional,
  wrapClassName,
  labelClassName,
}: InputGroupTitleProps) => (
  <div className={classNames(styles.inputGroupTitle, wrapClassName)}>
    <div className={classNames(styles.inputGroupTitle_label, labelClassName)}>
      <Text size="m" bold as="h3">
        {title}
        &nbsp;
      </Text>
      {isOptional && (
        <Text size="m" color="grayscale500">
          (optional)
        </Text>
      )}
    </div>
    {tooltip && (
      <Tooltip label={tooltip} withArrow>
        <Icons.AlertCircle color="grayscale500" size="m" />
      </Tooltip>
    )}
  </div>
);

export const SectionTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => (
  <Text
    size={6}
    bold
    color="grayscale900"
    className={[styles.sectionTitle, className].join(" ")}
  >
    {title}
  </Text>
);
