import moment, { isMoment, Moment } from "moment";

/**
 * Creates a Moment object by combining the time from `dateTime` with the date from `date`.
 *
 * @param {Date | Moment | string} dateTime - The time to be used for creating the Moment object.
 * @param {Date | Moment | string} [date] - The date to combine with the time from `dateTime`. If not provided, the current date is used.
 * @returns {Moment} A new Moment object combining the date and time.
 * @throws {Error} If `datetime` or `date` is incorrect.
 */
export const buildDateTime = (
  dateTime: Date | Moment | string,
  date?: Date | Moment | string,
) => {
  if (!dateTime) {
    throw new Error("Invalid date input");
  }

  const result = isMoment(dateTime) ? dateTime : moment(dateTime);

  if (!result.isValid()) {
    throw new Error("Invalid date input");
  }

  if (date) {
    const correctDate = moment(date);

    result
      .year(correctDate.year())
      .month(correctDate.month())
      .date(correctDate.date());
  }

  return result;
};
