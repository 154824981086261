import React, { FC } from "react";

import { EventDetailsHaveChangedMetadata } from "src/store/messenger";
import moment from "moment/moment";
import { NotificationTemplate } from "../notification-template";
import { useGetUserTimezone } from "../../../../../../../../../hooks/useGetUserTimezone";

type Props = {
  metadata: EventDetailsHaveChangedMetadata;
};

const EventDetailsHaveChanged: FC<Props> = ({ metadata }) => {
  const userTimeZone = useGetUserTimezone();

  const { event_id: eventId, event_name: eventName } = metadata.data;

  const newDate = metadata.data.new_date
    ? moment.utc(metadata.data.new_date).tz(userTimeZone)
    : undefined;
  const newStartTime = metadata.data.new_start_time
    ? moment.utc(metadata.data.new_start_time).tz(userTimeZone)
    : undefined;
  const newEndTime = metadata.data.new_end_time
    ? moment.utc(metadata.data.new_end_time).tz(userTimeZone)
    : undefined;

  return (
    <NotificationTemplate
      title="Event details have changed"
      event={{ title: eventName, link: `/catalog/event/${eventId}` }}
      newDateTime={{ date: newDate, startTime: newStartTime, endTime: newEndTime }}
    />
  );
};

export default EventDetailsHaveChanged;
