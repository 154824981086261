// FIX_ME:
/* eslint-disable import/no-cycle */
import React, { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "src/hooks/useStoreHooks";
// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { useGetCurrentUserQuery } from "../../store/User/api";

export interface AccessTokenPayload {
  sub?: number;
  email?: string;
  exp: number;
  scopes?: string[]; // registration, auth
}

export const AuthPartWrapper = ({
  children,
}: {
  children?: React.ReactElement | React.ReactNode;
}) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const location = useLocation();
  const searchParamsRef = useRef(useSearchParams()[0]);

  const navigate = useNavigate();

  const userInfo = useAppSelector((state) => state.user.userInfo);

  useEffect(() => {
    if (userInfo) {
      // authenticated users are redirected to their profile
      navigate(`/profile/${currentUser?.username}`, { replace: true });
    }
    // guests should /login first
    if (
      !(
        location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/registration") ||
        location.pathname.startsWith("/recover-password")
      )
    )
      navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  /*
  useEffect(() => {
    const authType =
      Array.from(searchParamsRef.current.keys()).length > 0
        ? AuthType.REGISTER_OR_LOGIN_WITH_GOOGLE_IN_PROCESS
        : AuthType.REGISTER_OR_LOGIN;
    dispatch(setStepForm(authType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, searchParamsRef.current]);
  */

  return <>{children || <Outlet context={searchParamsRef.current} />}</>;
};
