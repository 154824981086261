import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Email, PinCode, Token } from "../model/types";

export interface RegistrationMetadata {
  generatedUsername?: string;
  tempAccessToken?: string;
  firstName?: string;
  lastName?: string;
}

export interface Authorization {
  /** Valid auth code from an email */
  authCode: PinCode | undefined;
  /** Valid user email (after validation) */
  validEmail: Email | undefined;
  /** Location which used for redirect after auth flow */
  locationForRedirect: string | undefined;
  token: Token | undefined;
  isLoadingRequest: boolean;
  registrationMetadata: RegistrationMetadata | null;
}

const initialState: Authorization = {
  authCode: undefined,
  validEmail: undefined,
  locationForRedirect: undefined,
  token: undefined,
  isLoadingRequest: false,
  registrationMetadata: {},
};
const login = createSlice({
  name: "login",
  initialState,

  reducers: {
    setValidUserEmail(state, action: PayloadAction<Email | undefined>) {
      state.validEmail = action.payload;
    },
    setLocationForRedirect(state, action: PayloadAction<string | undefined>) {
      state.locationForRedirect = action.payload;
    },
    setAuthCode(state, action: PayloadAction<Email>) {
      state.authCode = action.payload;
    },
    setAuthToken(state, action: PayloadAction<Token>) {
      state.token = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoadingRequest = action.payload;
    },
    setRegistrationMetadata(
      state,
      action: PayloadAction<RegistrationMetadata | null>,
    ) {
      state.registrationMetadata = action.payload;
    },
    resetAuthData(state) {
      state.validEmail = undefined;
      state.authCode = undefined;
    },
  },
});
export const {
  resetAuthData,
  setAuthCode,
  setAuthToken,
  setIsLoading,
  setLocationForRedirect,
  setRegistrationMetadata,
  setValidUserEmail,
} = login.actions;

export default login.reducer;
