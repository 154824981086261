import { FC, memo } from "react";
import { Avatar, Text } from "@evvve/ui-kit";

import { ModalTemplate } from "src/close-part/features/Modals";
import { ModalTemplateProps } from "src/close-part/features/Modals/ModalTemplate";
import { deleteDialogStatics, leaveGroupStatics } from "src/constants";
import avatar_skeleton from "src/assets/icons/user_default_avatar.svg";
import event_default_cover from "src/assets/images/event_default_cover_16-9.png";

import { ChatVariant } from "src/store/messenger";
import { noop } from "lodash";
import s from "./style.module.scss";

type Statics = {
  title: string;
  caption: string;
  defaultAvatar: string;
};

const quitChatStatics: Record<ChatVariant, Statics> = {
  [ChatVariant.PRIVATE]: {
    ...deleteDialogStatics,
    defaultAvatar: avatar_skeleton,
  },
  [ChatVariant.SUPPORT]: {
    ...deleteDialogStatics,
    defaultAvatar: avatar_skeleton,
  },
  [ChatVariant.GROUP]: { ...leaveGroupStatics, defaultAvatar: event_default_cover },
};

type Props = {
  dialogType: ChatVariant;
  avatarUrl?: string;
  dialogCaption?: string;
  onClose?: VoidFunction;
  isOpenModal: boolean;
};

const QuitChatModal: FC<Props & Pick<ModalTemplateProps, "buttons">> = ({
  dialogType,
  avatarUrl,
  dialogCaption,
  buttons,
  onClose = noop,
  isOpenModal,
}) => {
  const currentStatics = quitChatStatics[dialogType];

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      onClose={onClose || noop}
      buttons={buttons}
      title={currentStatics.title}
      description={currentStatics.caption}
      className={s.modalWrap}
    >
      {dialogCaption && (
        <div className={s.dialogDescriptionWrap}>
          <Avatar
            src={avatarUrl || currentStatics.defaultAvatar}
            status="offline"
            size="m"
            alt=""
          />
          <Text size="m" color="grayscale900">
            {dialogCaption}
          </Text>
        </div>
      )}
    </ModalTemplate>
  );
};

export default memo(QuitChatModal);
