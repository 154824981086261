import React, { RefObject, useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { FormikProps } from "formik/dist/types";
import { useOutletContext } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import { CurrentUser } from "src/store/User/types";
import { usePatchCurrentUserMutation } from "src/store/User/api";
import { setUserInfo } from "src/store/User/slice";
import { parseErrorMessage } from "src/helpers/apiHelpers";
import { addToast } from "src/store/toast/slice";
import * as Yup from "yup";
import { AccountSettingComponentsProps, IOutletContext } from "../../service/types";
import { SocialMediaFormType } from "./service/types";
import { parseUserSocialLinks, socialLinksSerializer } from "./service/service";
import {
  DEFAULT_NOTIFICATION_ESTIMATE,
  InputMaxLength,
} from "../../../../../constants";
import { SocialMediaForm } from "./SocialMediaSettingsForm";
import { trimValue } from "../../../../../helpers/text";
import { optional } from "../../../../../helpers/validation";

const SocialMedia = ({ setDirty, innerRef }: AccountSettingComponentsProps) => {
  const [addSocialLinks, { isLoading }] = usePatchCurrentUserMutation();
  const user = useAppSelector((state) => state.user.userInfo);
  const dispatch = useAppDispatch();
  const OutletObject = useOutletContext<IOutletContext>();
  const [formikRef, setFormikRef] =
    useState<RefObject<FormikProps<SocialMediaFormType>>>();

  const validationSchema = Yup.object().shape({
    twitter: optional(InputMaxLength.XXL_1024),
    instagram: optional(InputMaxLength.XXL_1024),
    facebook: optional(InputMaxLength.XXL_1024),
    linkedin: optional(InputMaxLength.XXL_1024),
    website: optional(InputMaxLength.XXL_1024),
  });

  const handleOnSubmit = (
    values: SocialMediaFormType,
    { resetForm }: FormikHelpers<SocialMediaFormType>,
  ) => {
    const requestValues = {
      social_links: socialLinksSerializer(values),
    };

    // FIX_ME:
    // @ts-ignore
    addSocialLinks(requestValues)
      .unwrap()
      .then((response: CurrentUser) => {
        if (user)
          dispatch(setUserInfo({ ...user, social_links: response.social_links }));
        resetForm();
        dispatch(
          addToast({
            type: "success",
            content: "Changes saved",
            estimate: DEFAULT_NOTIFICATION_ESTIMATE,
          }),
        );
      })
      .catch((error) => {
        const errorData = parseErrorMessage(error);
        if (errorData) {
          alert(errorData.message);
        }
      });
  };

  useEffect(() => {
    // mobile
    if (innerRef) {
      setFormikRef(innerRef);
    }
    // desktop
    if (OutletObject?.socialRef) {
      setFormikRef(OutletObject.socialRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const socialLinks = parseUserSocialLinks(user);

  return (
    <Formik<SocialMediaFormType>
      enableReinitialize
      innerRef={formikRef}
      validationSchema={validationSchema}
      initialValues={{
        instagram: trimValue(socialLinks.instagram) ?? "",
        facebook: trimValue(socialLinks.facebook) ?? "",
        twitter: trimValue(socialLinks.twitter) ?? "",
        linkedin: trimValue(socialLinks.linkedin) ?? "",
        website: trimValue(socialLinks.website) ?? "",
      }}
      onSubmit={handleOnSubmit}
    >
      {(props) => (
        <SocialMediaForm
          {...props}
          setDirty={setDirty}
          isLoadingPatchUserSocialMedia={isLoading}
        />
      )}
    </Formik>
  );
};

export default React.memo(SocialMedia);
