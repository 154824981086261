import { Text } from "@evvve/ui-kit";
import { FC, memo } from "react";
import cn from "classnames";
import { noop } from "lodash";

import s from "./styles.module.scss";

type Props = {
  caption?: string;
  onClick: VoidFunction;
};

const MessageCaption: FC<Props> = ({ caption = "Not found", onClick }) => (
  <div className={s.name_message}>
    <span
      role="button"
      className={cn(s.clickable, {
        [s.nonClickable]: !caption,
      })}
      onClick={onClick}
      onKeyDown={noop}
      tabIndex={0}
    >
      <Text size="s" color="violet600">
        {caption}
      </Text>
    </span>
  </div>
);

export default memo(MessageCaption);
