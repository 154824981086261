import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React, { ReactElement } from "react";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../constants";

export interface Toast {
  type?: "error" | "warning" | "success";
  content: React.ReactNode | ReactElement | null;
  isOpen: boolean;
  estimate: number;
  id: number;
}

export interface Toasts {
  toasts: Toast[];
}

const initialState: Toasts = {
  toasts: [],
};

const createId = (toasts: Toast[]) => {
  const lastId = toasts.at(-1)?.id;
  if (lastId) {
    return lastId + 1;
  }

  return toasts.length + 1;
};

const buildDefaultToast = (
  id: number,
  content: string,
  type?: "error" | "warning" | "success",
): Toast => ({
  type,
  content,
  estimate: DEFAULT_NOTIFICATION_ESTIMATE,
  id,
  isOpen: true,
});

const toastSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addDefaultToast(state, action: PayloadAction<string>) {
      state.toasts.push(buildDefaultToast(createId(state.toasts), action.payload));
    },
    addErrorToast(state) {
      state.toasts.push(
        buildDefaultToast(createId(state.toasts), "Something went wrong", "error"),
      );
    },
    addToast(state, action: PayloadAction<Omit<Toast, "isOpen" | "id">>) {
      state.toasts.push({
        ...action.payload,
        id: createId(state.toasts),
        isOpen: true,
      });
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});
export const { addToast, removeToast, addErrorToast, addDefaultToast } =
  toastSlice.actions;

export default toastSlice.reducer;
