import moment from "moment/moment";

/**
 * Formats a moment object into a string in the "h:mm a" format (e.g., 8:45 p.m.).
 * changeTimeFormat Analog
 *
 * @param {moment.Moment} dateString - The moment object to format.
 * @returns {string} The formatted time in "h:mm a" format (e.g., "8:45 p.m.").
 */
export const formatMomentToAmPm = (dateString: moment.Moment): string =>
  dateString.format("h:mm a");
