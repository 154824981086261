import { FC } from "react";
import { Avatar } from "@evvve/ui-kit";
import eventGroupChatIcon from "src/assets/images/event_default_cover_16-9.png";
import { Placeholder } from "src/shared/ui";
import s from "./styles.module.scss";

// [CHAT] TODO: изменить на динамиеский? После релиза
const TMP_STATIC_STATUS = "offline";

type Props = {
  avatarUrl?: string;
  isPlaceholderView?: boolean;
};

const AvatarWithPlaceholder: FC<Props> = ({
  avatarUrl,
  isPlaceholderView = false,
}) => (
  <div className={s.avatar}>
    {!isPlaceholderView ? (
      <Avatar
        src={avatarUrl || eventGroupChatIcon}
        size="m"
        status={TMP_STATIC_STATUS}
        alt=""
        // status={dialogMate?.user.is_online ? "online" : "offline"}
      />
    ) : (
      <Placeholder width={48} height={48} isRounded />
    )}
  </div>
);

export default AvatarWithPlaceholder;
