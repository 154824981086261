import moment, { Moment } from "moment";

/**
 * Converts a given date or date string to a Moment.js object,
 * setting seconds and milliseconds to zero.
 *
 * @param {Date | string} dateWithTime - The date or date string to be converted.
 * @returns {Moment} A Moment.js object representing the provided date with seconds and milliseconds set to zero.
 * @throws {TypeError} If the input is neither a Date object nor a valid date string.
 */
export const convertSelectedTimeToMoment = (dateWithTime: Date | string): Moment => {
  // Check if the input is a valid Date object or a non-empty string
  // if (!(dateWithTime instanceof Date) && typeof dateWithTime !== "string") {
  //   throw new TypeError("Input must be a Date object or a non-empty string.");
  // }

  // Parse the input using Moment.js
  const momentObj = moment(dateWithTime);

  // // Check if the parsed Moment object is valid
  // if (!momentObj.isValid()) {
  //   throw new TypeError("Invalid date or date string provided.");
  // }

  // Set seconds and milliseconds to zero
  return momentObj.seconds(0).milliseconds(0);
};
