/**
 * Checks if the given time is after or equal to the end time.
 *
 * @param {Date | number} time - The time to check, represented as a Date object or a timestamp in milliseconds.
 * @param {Date | number} end - The end time, represented as a Date object or a timestamp in milliseconds.
 * @returns {boolean} Returns true if 'time' is after or equal to 'end'; otherwise, returns false.
 */
export const isAfterRange = (time: Date | number, end: Date | number): boolean => {
  const timeValue = time instanceof Date ? time.getTime() : time;
  const endValue = end instanceof Date ? end.getTime() : end;

  return timeValue >= endValue;
};
