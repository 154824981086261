import React, { useMemo } from "react";
import { Text } from "@evvve/ui-kit";
import { noop } from "lodash";
import cn from "classnames";
import commonStyles from "src/styles/common.module.scss";
import { convertNumberThousandsToChars } from "src/helpers/numbers";
import { useModal } from "src/hooks";
import { useGetFollowersQuery, useGetFollowingsQuery } from "src/store/User/api";
import { PersonCardBlockProps } from "src/close-part/features/Person/types";
import styles from "./style.module.scss";
// eslint-disable-next-line import/no-cycle
import { FollowListModal } from "../FollowList/FollowListModal";

interface FollowCounterProps {
  title: string;
  count: number;
  onClick: () => void;
  inline?: boolean;
}
const Counter = ({ title, count, onClick, inline }: FollowCounterProps) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={cn(styles.counter, {
      [styles.counter_columnMode]: !inline,
      [commonStyles.readonly]: count === 0,
    })}
    onClick={onClick}
    onKeyDown={noop}
  >
    <Text size={["s", "s", "s", "s", "s", 6]} fontWeight={600}>
      {convertNumberThousandsToChars(count || 0) || 0}
    </Text>
    <Text size={["xs", "xs", "xs", "xs", "xs", "s"]}>{title}</Text>
  </div>
);

interface FollowCounterBlockProps extends PersonCardBlockProps {
  isCurrentUserProfileOwner: boolean;
  className?: string;
}

export const FollowCounterBlock = ({
  profileOwner,
  isCurrentUserProfileOwner,
  isMobile,
  className,
}: FollowCounterBlockProps) => {
  const followingModal = useModal();
  const followersModal = useModal();

  // List.length isn't used in counter,
  // as otherwise a /following refetch will affect the Following list while it's open
  const { data: myFollowingUsers } = useGetFollowingsQuery(undefined, {
    skip: !isCurrentUserProfileOwner,
  });

  const { data: myFollowersUsers } = useGetFollowersQuery(undefined, {
    skip: !isCurrentUserProfileOwner,
  });

  const followersCounter = useMemo(
    () => {
      const count =
        (Number.isInteger(profileOwner.followers_count)
          ? profileOwner.followers_count
          : myFollowersUsers?.length) || 0;

      return (
        <Counter
          title="Followers"
          count={count}
          onClick={() => followersModal.openModal()}
          inline={isMobile}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileOwner.followers_count, myFollowersUsers?.length, isMobile],
  );

  const followingCounter = useMemo(
    () => {
      const count =
        (Number.isInteger(profileOwner.following_count)
          ? profileOwner.following_count
          : myFollowingUsers?.length) || 0;

      return (
        <Counter
          title="Following"
          count={count}
          onClick={() => followingModal.openModal()}
          inline={isMobile}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileOwner.following_count, myFollowingUsers?.length, isMobile],
  );

  return (
    <div
      className={cn(
        styles.followBlock,
        { [commonStyles.readonly]: !isCurrentUserProfileOwner },
        className,
      )}
    >
      {followersCounter}
      {followingCounter}
      {isCurrentUserProfileOwner && (
        <>
          <FollowListModal
            title="Followers"
            userList={myFollowersUsers}
            modalControls={followersModal}
            isMobile={isMobile}
          />
          <FollowListModal
            title="Following"
            userList={myFollowingUsers}
            modalControls={followingModal}
            isMobile={isMobile}
          />
        </>
      )}
    </div>
  );
};
