import { FC, useCallback, useEffect, useRef, useState, MouseEvent } from "react";
import { Avatar, Text } from "@evvve/ui-kit";
import cn from "classnames";
import avatar_skeleton from "src/assets/icons/user_default_avatar.svg";
import { Nullable } from "src/shared/utils/utility-types";
import ContextMenu, { ContextMenuTypes } from "src/shared-features/ContextMenu";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { noop } from "lodash";
import s from "./styles.module.scss";

type Props = {
  avatarUrl?: Nullable<string>;
  fullName?: Nullable<string>;
  id: string;
};

type MenuPosition = {
  left: number;
  top: number;
};

const ParticipantItem: FC<Props> = ({ id, avatarUrl, fullName }) => {
  const { chatId } = useMessengerViewController();
  const [menuPosition, setMenuPosition] = useState<Nullable<MenuPosition>>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [menuIsOpened, setMenuIsOpened] = useState(false);

  const menuItemRef = useRef<HTMLLIElement>(null);

  const updateMenuPosition = useCallback(() => {
    if (menuItemRef.current) {
      const rectBoundary = menuItemRef.current.getBoundingClientRect();
      const left = rectBoundary.right;
      const { top } = rectBoundary;
      setMenuPosition({ left, top });
    }
  }, [menuItemRef]);

  const closeMenu = () => {
    setMenuIsOpened(false);
  };

  const onOpenMenu = (event: MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    setMenuIsOpened(true);
  };

  const goToProfile = () => {
    const path = `${location.pathname}?chatId=${chatId}&profileId=${id}`;

    navigate(path, {
      state: {
        previousSearch: location.search,
      },
      replace: true,
    });
  };

  // [CHAT] TODO: Кажется тут лучше использовать useLayoutEffect() После релиза
  useEffect(() => {
    if (menuIsOpened) updateMenuPosition();
  }, [menuIsOpened, updateMenuPosition]);

  const menuItems = [
    {
      type: ContextMenuTypes.ViewProfile,
      handler: goToProfile,
    },
  ];

  return (
    <>
      <li onContextMenu={onOpenMenu} ref={menuItemRef}>
        <div onClick={goToProfile} onKeyDown={noop} tabIndex={0} role="button">
          <div className={s.wrap}>
            <div className={s.inner}>
              <Avatar
                src={avatarUrl || avatar_skeleton}
                size="m"
                alt={`Icon: ${fullName}`}
                className={s.avatar}
                status="offline" // ?
              />
              {fullName && (
                <Text size="m" color="violet" className={cn(s.info, s.ellipsis)}>
                  {fullName}
                </Text>
              )}
            </div>
          </div>
        </div>
      </li>
      {menuIsOpened && menuPosition && (
        <ContextMenu
          onClickBlockingArea={closeMenu}
          position={{ ...menuPosition }}
          actions={menuItems}
        />
      )}
    </>
  );
};

export default ParticipantItem;
