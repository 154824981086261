import React, { useEffect, useRef, useState } from "react";
import { Avatar, Icons } from "@evvve/ui-kit";
import avatar_skeleton from "src/assets/icons/user_default_avatar.svg";
import useOutsideClick from "src/hooks/useOutsideClick";
import { noop } from "lodash";
import cn from "classnames";
import HeaderLayout from "./HeaderLayout";
import style from "./style.module.scss";
import ButtonChat from "./ButtonChat";
import HeaderMenu from "./HeaderMenu";
import { useAppSelector } from "../../../hooks/useStoreHooks";
import { CurrentUser } from "../../../store/User/types";
import { ResponsiveLayoutProps } from "../../../hooks/useResponsiveLayout";
import Link from "../../../shared-features/Link";

export enum AppPage {
  CREATE_EVENT = "CREATE_EVENT",
  EVENT_SPACE = "EVENT_SPACE",
  CHECK_DEVICES = "CHECK_DEVICES",
}

export interface HeaderProps {
  onClickChat?: () => void;
  isOpenChat?: boolean;
  page?: AppPage;
  desktopLayoutOnly?: boolean;
}

const HeaderClosePart = ({
  onClickChat,
  isOpenChat = false,
  page,
  desktopLayoutOnly,
}: HeaderProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const isOutside = useOutsideClick(openMenu, node);
  const responsiveLayout = useAppSelector(
    (state) => state.user.responsiveLayoutConfig,
  );

  const user: CurrentUser | null = useAppSelector((state) => state.user.userInfo);

  // const { data } = useGetIfHasUnreadMessagesQuery();

  useEffect(() => {
    if (isOutside) setOpenMenu(false);
  }, [isOutside]);

  const renderPadding = () => {
    switch (page) {
      case AppPage.CREATE_EVENT:
      case AppPage.CHECK_DEVICES:
        return style.padding_12_20;
      default:
        return style.padding_12_20;
    }
  };

  const renderAccountElements = (responsiveLayoutProps: ResponsiveLayoutProps) => (
    <div className={style.buttonsSection}>
      <Link to="/plans" className={cn(style.iconButton, style.createEventButton)}>
        <Icons.PlusSquare size="m" color="violet600" />
      </Link>

      <Link to="/catalog" className={style.iconButton}>
        <Icons.Grid size="m" color="violet600" />
      </Link>

      {!desktopLayoutOnly && (
        <ButtonChat
          isViolet={openMenu || isOpenChat}
          hasUnreadMessages={false}
          // hasUnreadMessages={data?.unread_messages || false}
          onClick={onClickChat}
        />
      )}
      <div
        ref={node}
        className={style.avatarCp}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
      >
        {responsiveLayoutProps.isMobile ? (
          <Icons.Menu size="m" color="violet600" />
        ) : (
          <>
            <Avatar
              size="s"
              status="offline"
              alt="avatar"
              src={user?.avatar_url ?? avatar_skeleton}
            />
            <div className={openMenu ? style.arrowDown : style.arrowUp}>
              <Icons.ChevronDown />
            </div>
          </>
        )}
        <div>
          <HeaderMenu openMenu={openMenu} />
        </div>
      </div>
    </div>
  );

  return (
    <HeaderLayout
      type="private"
      desktopLayoutOnly={desktopLayoutOnly}
      className={cn(
        style.headerClosedPart,
        {
          [style.headerLayout_speaker_org]: desktopLayoutOnly,
        },
        renderPadding(),
      )}
    >
      {responsiveLayout ? renderAccountElements(responsiveLayout) : <></>}
    </HeaderLayout>
  );
};

export default HeaderClosePart;
