import { Icons } from "@evvve/ui-kit";
import { noop } from "lodash";
import style from "./style.module.scss";

const { MessageSquare } = Icons;

export interface ButtonChatProps {
  isViolet?: boolean;
  hasUnreadMessages: boolean;
  onClick?: () => void;
}

const ButtonChat = ({
  hasUnreadMessages,
  onClick,
  isViolet = false,
}: ButtonChatProps) => (
  <div
    onClick={onClick}
    onKeyDown={noop}
    role="button"
    tabIndex={0}
    className={`
        ${style.wrap} ${hasUnreadMessages ? style.messageBottom : ""}
        ${isViolet && style.wrap_violet}
      `}
  >
    <MessageSquare size="m" color="violet600" />
    {/* {hasUnreadMessages && <p className={style.amount } />} */}
  </div>
);

export default ButtonChat;
