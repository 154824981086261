import { Moment } from "moment";
import { buildDateTime } from "../buildDateTime";

/**
 * Formats a date into a string with the format "MMM D, YYYY" (e.g., "Jan 23, 2023").
 *
 * @param {string | Date | Moment} date - The date to format, which can be a string, Date object, or Moment object.
 * @returns {string} The formatted date as a string in "MMM D, YYYY" format.
 */
export function changeDateFormat(date: string | Date | Moment): string {
  return buildDateTime(date)?.format("ll");
}
