import moment, { isMoment, Moment } from "moment";
import { buildDateTime } from "../buildDateTime";

/**
 * Formats a given date into a 12-hour AM/PM format with "a.m." and "p.m.".
 *
 * @param {string | Date | Moment} date - The input date, which can be a string, Date object, or Moment instance.
 * @returns {string} The formatted time in "h:mm a.m./p.m." format (e.g., "8:45 p.m.").
 *  * @throws {Error} If the input date is invalid.
 */
export const changeTimeFormat = (date: string | Date | Moment): string => {
  if (date === undefined || date === null) {
    throw new Error("Invalid date input");
  }

  const parsedDate = isMoment(date) ? date : moment(date);

  if (!parsedDate.isValid()) {
    throw new Error("Invalid date input");
  }

  return buildDateTime(parsedDate)
    .format("h:mm a")
    .replace("pm", "p.m.")
    .replace("am", "a.m.")
    .replace(" ", "\u0020");
};
