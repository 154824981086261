import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";
import { noop } from "lodash";
import avatarSkeleton from "src/assets/icons/user_default_avatar.svg";
import { CurrentUser } from "src/store/User/types";
import s from "./styles.module.scss";
import Link from "../../../../../../../shared-features/Link";

type Props = {
  profileInfo: Omit<CurrentUser, "is_using_password">;
};

const ProfileHead: FC<Props> = ({ profileInfo }) => (
  <Link to={`/profile/${profileInfo.username}`} keepSearchParams className={s.wrap}>
    <div className={s.head}>
      <img
        className={s.avatar}
        src={profileInfo?.avatar_url || avatarSkeleton}
        alt=""
      />
      <div className={s.link} onKeyDown={noop} role="button" tabIndex={0}>
        <Text size="m" fontWeight={600} className={s.text}>
          {profileInfo.first_name}
        </Text>
        <Text size="m" fontWeight={600} className={s.text}>
          {profileInfo.last_name}
        </Text>
        <Text size="s" color="violet600" className={s.text}>
          {`@${profileInfo?.username}`}
        </Text>
      </div>
    </div>
  </Link>
);

export default ProfileHead;
