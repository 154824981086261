import { useNavigate } from "react-router-dom";
import { appApi } from "../../api/api";
import { chatApi } from "../../api/chatApi";
import { apiPayment } from "../../api/apiPayment";
import { useLogoutMutation } from "../service/api";
import { useAppDispatch } from "../../hooks/useStoreHooks";
import { getSocketWithAuth } from "../../store/messenger/socket/createSocketFactory";

export const useLogout = (redirectPath?: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [logoutRequest] = useLogoutMutation();

  const cleanUpLocalStorage = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("chat-token");
    localStorage.removeItem("chat-refresh-token");
  };

  const resetStore = () => {
    dispatch(appApi.util.resetApiState());
    dispatch(chatApi.util.resetApiState());
    dispatch(apiPayment.util.resetApiState());

    dispatch({ type: "RESET" });
  };

  const onLogOut = async () => {
    navigate(redirectPath || "/");

    resetStore();
    cleanUpLocalStorage();

    // Disconnect chat socket
    const socket = await getSocketWithAuth();
    socket.disconnect();

    logoutRequest().unwrap();
  };

  return { onLogOut };
};
