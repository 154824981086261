import { useGetCurrentUserQuery } from "../store/User/api";
import { useAppSelector } from "./useStoreHooks";

interface AmIActualUserState {
  startFetch: () => Promise<boolean | undefined>;
}

/**
 * Hook for compare current user ID from Redux state with actual user ID from server
 */
export const useAmIActualUser = (): AmIActualUserState => {
  const { refetch } = useGetCurrentUserQuery();

  const userIdFromState = useAppSelector((state) => state.user.userInfo?.unique_id);

  const startFetch = async () =>
    refetch().then(({ data }) => {
      if (!data) return;
      if (userIdFromState === data.unique_id) return true;

      return false;
    });

  return { startFetch };
};
