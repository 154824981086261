import { Avatar, Text } from "@evvve/ui-kit";
import React, {
  FC,
  MouseEvent,
  MouseEventHandler,
  memo,
  useState,
  useRef,
} from "react";
import eventGroupChatIcon from "src/assets/images/event_default_cover_16-9.png";
import { ChatDTO, ChatVariant } from "src/store/messenger";
import { noop } from "lodash";
import s from "./styles.module.scss";
import { ChatIcon } from "../chat-icon";
import { LastMessage } from "../last-message";
import { Counter } from "../counter";
import ContextMenuWrapper from "../../../chat-room/ui/context-menu-wrapper/ContextMenuWrapper";
import { useGetChatMateComplex } from "../../../../hooks";

type Props = {
  dialogData: ChatDTO;
  onClick: VoidFunction;
  onMouseOver: MouseEventHandler<HTMLDivElement>;
};

// [CHAT] TODO: прокидывать динамический статус? После релиза
const TMP_DEFAULT_STATUS = "offline";

const ChatListItem: FC<Props> = ({ dialogData, onClick, onMouseOver }) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const { chatMateProfile } = useGetChatMateComplex({ chatId: dialogData.chat_id });

  const contextMenuHandler = (e: MouseEvent) => {
    e.preventDefault();
    if (dialogData.system || dialogData.type === ChatVariant.SUPPORT) return;

    setIsContextMenuOpen(true);
  };

  return (
    <div className={s.wrap} onMouseOver={onMouseOver} onFocus={noop}>
      <div
        className={s.inner}
        onClick={onClick}
        onContextMenu={contextMenuHandler}
        onKeyDown={noop}
        ref={menuButtonRef}
        role="button"
        tabIndex={0}
      >
        <Avatar
          src={dialogData.avatar_url || eventGroupChatIcon}
          size="s"
          status={TMP_DEFAULT_STATUS}
          // status={dialogData.isOnline ? "online" : "offline"}
          alt={`Icon: ${dialogData.title}`}
          className={s.avatar}
        />
        <div className={s.info}>
          <header className={s.header}>
            <ChatIcon chatType={dialogData.type} isSystem={dialogData.system} />
            {dialogData.title && (
              <div className={s.name_style}>
                <Text size={["s", "s", "s", "s", "xs", "s"]} color="grayscale1000">
                  {dialogData.title}
                </Text>
              </div>
            )}
          </header>
          <footer className={s.footer}>
            <LastMessage message={dialogData.last_message} />
            <Counter unreadMessagesCount={dialogData.unread_messages_count} />
          </footer>
        </div>
      </div>
      <ContextMenuWrapper
        menuIsOpened={isContextMenuOpen}
        closeMenu={() => setIsContextMenuOpen(false)}
        menuButtonRef={menuButtonRef}
        chatInfo={dialogData}
        {...(dialogData.type === ChatVariant.PRIVATE && { chatMateProfile })}
      />
    </div>
  );
};

export default memo(ChatListItem);
