import { Moment } from "moment";

/**
 * Replaces the date components (year, month, day) of the Moment object `val`
 * with those from `targetDate`, preserving the time and timezone.
 *
 * @param {Moment} val - The Moment object whose date components are to be replaced.
 * @param {Moment} targetDate - The Moment object providing the new date components.
 * @returns {Moment} A new Moment object with the updated date.
 */
export const replaceDate = (val: Moment, targetDate: Moment): Moment => {
  const result = val.clone();

  return result
    .year(targetDate.year())
    .month(targetDate.month())
    .date(targetDate.date());
};

/**
 * Calculates the end time by replacing the date of the Moment object `val`
 * with the date from `eventStart`. If the resulting end time is before
 * `eventStart`, adds one day.
 *
 * @param {Moment} val - The Moment object whose date is to be replaced.
 * @param {Moment} eventStart - The Moment object representing the event start time.
 * @returns {Moment} A new Moment object representing the calculated end time.
 */
export const calcEndTime = (val: Moment, eventStart: Moment): Moment => {
  const end = replaceDate(val, eventStart); // Clone val and replace date
  if (end.isBefore(eventStart)) {
    end.add(1, "days");
  }

  return end;
};
