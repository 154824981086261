import React, { useState } from "react";
import { Button, Input, Text, VisibilityButton } from "@evvve/ui-kit";
import { useModal } from "src/hooks";
import { Formik, FormikHelpers } from "formik";
import { FormikProps } from "formik/dist/types";
import { useUpdatePasswordMutation } from "src/store/User/api";
import { ApiError, parseErrorMessage } from "src/helpers/apiHelpers";
import { addToast } from "src/store/toast/slice";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import * as Yup from "yup";
import {
  validateNewPassword,
  validateCurrentPassword,
  validateNewPasswordRepeat,
} from "src/helpers/validation";
import { useValidationByInput } from "src/hooks/useValidationByInput";
import { PasswordRequirements } from "./PasswordRequirements";
import style from "./style.module.scss";
import ModalTemplate from "../ModalTemplate";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../../../constants";

const { title, inputs, submit } = style;

interface ChangePasswordForm {
  currentPassword: string;
  newPassword: string;
  copyPassword: string;
}

interface State {
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showPasswordCopy: boolean;
}

const initialState: State = {
  showCurrentPassword: false,
  showNewPassword: false,
  showPasswordCopy: false,
};

const ChangePasswordModal = () => {
  const { openModal, isOpenModal, closeModal } = useModal();
  const [updateUserPassword, { isLoading }] = useUpdatePasswordMutation();
  const [isVisible, setIsVisible] = useState<State>(initialState);
  const dispatch = useAppDispatch();

  const resetPasswordVisibilityState = () => {
    setIsVisible(initialState);
  };

  // FIX_ME:
  // eslint-disable-next-line react/no-unstable-nested-components
  const Form = ({
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldError,
  }: FormikProps<ChangePasswordForm>) => {
    const isSubmitDisabled = useValidationByInput(touched, errors);

    return (
      <form onBlur={handleBlur} onSubmit={(e) => handleSubmit(e)}>
        <div className={inputs}>
          <Input
            label="Current password"
            name="currentPassword"
            value={values?.currentPassword || ""}
            placeholder="Current password"
            onChange={(e) => {
              setFieldError("currentPassword", "");
              handleChange(e);
            }}
            type={isVisible.showCurrentPassword ? "text" : "password"}
            error={touched.currentPassword && errors.currentPassword}
            endAdornment={
              <VisibilityButton
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    showCurrentPassword: !isVisible.showCurrentPassword,
                  })
                }
                showPassword={isVisible.showCurrentPassword}
              />
            }
          />
          <Input
            label="New password"
            name="newPassword"
            value={values?.newPassword || ""}
            placeholder="New password"
            onChange={(e) => {
              setFieldError("newPassword", "");
              handleChange(e);
            }}
            type={isVisible.showNewPassword ? "text" : "password"}
            error={touched.newPassword && errors.newPassword}
            endAdornment={
              <VisibilityButton
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    showNewPassword: !isVisible.showNewPassword,
                  })
                }
                showPassword={isVisible.showNewPassword}
              />
            }
          />
          <Input
            label="Repeat new password"
            name="copyPassword"
            value={values?.copyPassword || ""}
            placeholder="Repeat new password"
            onChange={(e) => {
              setFieldError("copyPassword", "");
              handleChange(e);
            }}
            type={isVisible.showPasswordCopy ? "text" : "password"}
            error={touched.copyPassword && errors.copyPassword}
            endAdornment={
              <VisibilityButton
                onClick={() =>
                  setIsVisible({
                    ...isVisible,
                    showPasswordCopy: !isVisible.showPasswordCopy,
                  })
                }
                showPassword={isVisible.showPasswordCopy}
              />
            }
          />
        </div>
        <div className={submit}>
          <Button
            theme="violet"
            size="l"
            type="solid"
            htmlType="submit"
            disabled={isLoading || isSubmitDisabled}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    );
  };

  const validationSchema = Yup.object().shape({
    currentPassword: validateCurrentPassword(),
    newPassword: validateNewPassword(),
    copyPassword: validateNewPasswordRepeat("newPassword"),
  });

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      onClose={() => {
        closeModal();
        resetPasswordVisibilityState();
      }}
      OpenElement={
        <Button
          size="m"
          theme="violet"
          type="outline"
          onClick={() => {
            openModal();
          }}
        >
          Change password
        </Button>
      }
    >
      <div className={title}>
        <Text size={[6, 6, 4]} bold>
          Change password
        </Text>
      </div>
      <PasswordRequirements />
      <Formik<ChangePasswordForm>
        initialValues={{ currentPassword: "", newPassword: "", copyPassword: "" }}
        validateOnBlur
        validateOnChange={false}
        onSubmit={async (
          values,
          formikHelpers: FormikHelpers<ChangePasswordForm>,
        ) => {
          try {
            const requestValues = {
              old_password: values.currentPassword,
              new_password: values.newPassword,
            };
            await updateUserPassword(requestValues).unwrap();
            closeModal();
            formikHelpers.resetForm();
            resetPasswordVisibilityState();
            dispatch(
              addToast({
                type: "success",
                content: "Password changed",
                estimate: DEFAULT_NOTIFICATION_ESTIMATE,
              }),
            );
          } catch (error) {
            if (!(error as ApiError).data) throw error;
            const errorData = parseErrorMessage(error as ApiError);
            if (errorData.code === "wrong_old_password") {
              formikHelpers.setFieldError(
                "currentPassword",
                "The password is incorrect. Please try again",
              );
            }
          }
        }}
        validationSchema={validationSchema}
        component={Form}
      />
    </ModalTemplate>
  );
};
export default ChangePasswordModal;
