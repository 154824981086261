import { FC, ReactElement } from "react";
import { Icons } from "@evvve/ui-kit";
import { ChatVariant, messengerApi, type ChatDTO } from "src/store/messenger";
import { MessengerViewVariant } from "src/close-part/features/Messenger/types";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { noop } from "lodash";
import cn from "classnames";
import commonStyle from "src/styles/common.module.scss";
import s from "./styles.module.scss";
import { AvatarBlock } from "../avatar-block";
import { TitleWithPlaceholder } from "../title-block";

type Props = {
  chatInfo?: ChatDTO;
  menuButtonEl?: ReactElement;
  chatMateId?: string;
  isLoading: boolean;
};

const CommonHeader: FC<Props> = ({
  chatInfo,
  menuButtonEl,
  chatMateId,
  isLoading,
}) => {
  const { goToView, goToChatList } = useMessengerViewController();
  const prefetchChatList = messengerApi.usePrefetch("getChatList");

  const isPrivateChat = chatInfo && chatInfo.type === ChatVariant.PRIVATE;

  const openChatProfileView = () => {
    if (isPrivateChat && chatMateId) {
      goToView(MessengerViewVariant.PROFILE, { profileId: chatMateId });
    } else {
      throw new Error(
        "[CHAT]: There is no chatMate and it is group or support chat",
      );
    }
  };

  const prefetchData = () => {
    prefetchChatList({ lastEvaluatedKey: null });
  };

  return (
    <header className={cn(s.header)}>
      <div className={s.left_side}>
        <div
          onClick={goToChatList}
          onKeyDown={noop}
          role="button"
          tabIndex={0}
          aria-labelledby="TODO:"
          onMouseOver={prefetchData}
          onFocus={noop}
          className={cn(s.arrow, s.interactive, commonStyle.noTouchCallout)}
        >
          <Icons.ChevronLeft size="m" color="grayscale600" />
        </div>
        <div
          className={cn(s.avatarAndTitle, s.interactive, commonStyle.noTouchCallout)}
          onClick={openChatProfileView}
          onKeyDown={noop}
          role="button"
          tabIndex={0}
        >
          <AvatarBlock
            avatarUrl={chatInfo?.avatar_url}
            isPlaceholderView={!chatInfo || isLoading}
          />
          <div className={s.textWrapper}>
            <TitleWithPlaceholder isPlaceholderView={!chatInfo || isLoading}>
              {chatInfo?.title}
            </TitleWithPlaceholder>
          </div>
        </div>
      </div>
      {menuButtonEl}
    </header>
  );
};

export default CommonHeader;
