/**
 * Checks if a given time is within a specified range.
 *
 * @param {Date | number} time - The time to check, either as a Date object or a timestamp.
 * @param {Date | number} start - The start of the range, either as a Date object or a timestamp.
 * @param {Date | number} end - The end of the range, either as a Date object or a timestamp.
 * @returns {boolean} True if the time is within the range (inclusive), otherwise false.
 */
export const isInRange = (
  time: Date | number,
  start: Date | number,
  end: Date | number,
): boolean => start <= time && time <= end;
