import { ChatMessageDTO, NewMessageSocketEvent } from "../types";

export const normalizeLastMessage = (
  socketEventData: NewMessageSocketEvent,
): ChatMessageDTO => ({
  chat_id: socketEventData.message.chat_id,
  datetime: socketEventData.message.datetime,
  id: socketEventData.message.id,
  system_metadata: socketEventData.message.system_metadata,
  text: socketEventData.message.text,
  type: socketEventData.message.type,
  user: socketEventData.user
    ? {
        data: {
          user_avatar: socketEventData.user.data.user_avatar,
          name: socketEventData.user.data.name,
        },
        id: socketEventData.user.user_id,
      }
    : null,
});
