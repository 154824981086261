// FIX_ME:
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from "react";
import { Button, PhotoUploader, Text } from "@evvve/ui-kit";
import { useModal } from "src/hooks";
import UploaderButton from "src/close-part/features/UploaderButton";
import { fileReader } from "src/helpers/fileReader";
import { uploadFile } from "src/helpers/uploadFile";
import {
  useGetDefaultAvatarQuery,
  useLazyGetCurrentUserQuery,
  useUploadAvatarMutation,
} from "src/store/User/api";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import { setUserInfo } from "src/store/User/slice";
import style from "./style.module.scss";
import ModalTemplate from "../ModalTemplate";

const { title, submit, buttons, avatar } = style;

export interface ModalProps {
  onSave?: (file?: string) => void;
  OpenElement: (open: () => void) => React.ReactNode;
  file?: string;
  // setFile?: (file: string | undefined) => void;
}

export interface UploaderProps {
  imageFile?: string;
}

const UploaderImage = ({ imageFile }: UploaderProps) => (
  <PhotoUploader onChange={() => null} readonly srcImage={imageFile} />
);

const UploadAvatarModal = ({ onSave, OpenElement, file }: ModalProps) => {
  const [localFile, setLocalFile] = useState<string | undefined>(file);
  const [userAction, setUserAction] = useState<"delete" | "upload" | null>(null);
  const [value, setValue] = useState<File>();
  const [error, setError] = useState<string>("");
  const [defaultImgUrl, setDefaultImgUrl] = useState<string>("");
  const { isOpenModal, closeModal, openModal } = useModal();
  const [uploadAvatar] = useUploadAvatarMutation();
  const [trigger] = useLazyGetCurrentUserQuery();
  const { data: imgUrl, refetch } = useGetDefaultAvatarQuery();
  const [isLoading, setIsLoading] = useState(false);

  const SUPPORTED_TYPES = "image/png, image/jpeg, image/webp";
  const SUPPORTED_TYPES_FOR_MACOS = ["image/jpeg", "image/png", "image/webp"];
  const SUPPORTED_FILE_EXTENSIONS_FOR_WINDOWS = [".png", ".jpeg", ".jpg", ".webp"]; // Windows: file.type === ""

  const userHasDefaultImage =
    localFile === imgUrl?.url || localFile === defaultImgUrl;

  const dispatch = useAppDispatch();

  const handleUploadAvatar = async (value: File) => {
    setIsLoading(true);
    const S3data = await uploadFile(value, "user_avatar");
    if (S3data) {
      await uploadAvatar({ key: S3data.key }).unwrap();
      const currentUser = await trigger().unwrap();
      closeModal();
      dispatch(setUserInfo(currentUser));
    }
    setIsLoading(false);
  };

  const handleDeleteAvatar = async () => {
    setIsLoading(true);
    await uploadAvatar({ key: null }).unwrap();
    const newData = await trigger().unwrap();
    if (newData) {
      closeModal();
      dispatch(setUserInfo(newData));
    }
    setIsLoading(false);
  };

  return (
    <ModalTemplate
      isOpen={isOpenModal}
      onClose={() => {
        closeModal();
        setLocalFile(file);
        setError("");
      }}
      OpenElement={OpenElement(openModal)}
    >
      <div className={title}>
        <Text size={[6, 6, 4]} bold>
          Change photo
        </Text>
      </div>
      <div className={avatar}>
        <UploaderImage imageFile={localFile} />
        {error && (
          <Text size="s" color="red">
            {error}
          </Text>
        )}
      </div>
      <div className={buttons}>
        <UploaderButton
          disabled={isLoading}
          acceptedTypes={SUPPORTED_TYPES}
          onChange={(e) => {
            const target = e.target as HTMLInputElement;
            const fileAvatar: File = (target.files as FileList)[0];

            if (fileAvatar) {
              const hasCorrectSize = fileAvatar.size <= 10 * 1024 * 1024;
              const hasCorrectType = fileAvatar.type
                ? SUPPORTED_TYPES_FOR_MACOS.includes(fileAvatar.type)
                : SUPPORTED_FILE_EXTENSIONS_FOR_WINDOWS.some((ext) =>
                    fileAvatar.name.endsWith(ext),
                  );

              if (hasCorrectSize && hasCorrectType) {
                fileReader(e, setLocalFile);
                setValue(fileAvatar);
                setUserAction("upload");
                setError("");
              } else {
                switch (true) {
                  case !hasCorrectType:
                    setError("The file format is incorrect");
                    break;
                  case !hasCorrectSize:
                    setError("The file size exceeds 10MB");
                    break;
                  default:
                    break;
                }
              }
            }
          }}
        />
        <Button
          type="flat"
          theme="violet"
          disabled={userHasDefaultImage || isLoading}
          startIcon="Trash_2"
          onClick={async () => {
            const defaultUrl = await refetch().unwrap();
            setLocalFile(defaultUrl?.url);
            setDefaultImgUrl(defaultUrl?.url);
            setUserAction("delete");
            setError("");
          }}
        >
          Delete
        </Button>
      </div>
      <div className={submit}>
        <Button
          type="solid"
          disabled={Boolean(error) || isLoading}
          theme="violet"
          size="m"
          onClick={() => {
            onSave?.(localFile);
            if (userAction === "upload" && value) {
              handleUploadAvatar(value);
            } else if (userAction === "delete") {
              handleDeleteAvatar();
            } else {
              closeModal();
            }
          }}
          isLoading={isLoading}
        >
          Save
        </Button>
      </div>
    </ModalTemplate>
  );
};
export default UploadAvatarModal;
