import {
  AuthByCodeRequest,
  AuthByPasswordRequest,
  AuthEmailResponse,
  AuthResponse,
  GoogleAuthUrlResponse,
  Token,
  RegistrationPayload,
  ResetPasswordRequestOld,
} from "./types";
import { appApi } from "../../api/api";
import {
  CreateUserRequest,
  CreateUserResponse,
  ForgetPasswordResponse,
  PasswordRecoveryCodeRequest,
  RequestCodeRequest,
  RequestCodeResponse,
  ResetPasswordRequest,
  SignInResponse,
  VerifyEmailRequest,
  VerifySignUpEmailResponse,
} from "../model/types";

/*
https://evvve.atlassian.net/wiki/spaces/KB/pages/704479238/Authentication+Authorization+Flow
 */

const authApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    requestCode: builder.mutation<AuthEmailResponse, string>({
      query: (email: string) => ({
        url: "/auth/request-code",
        method: "POST",
        body: { email },
      }),
    }),
    register: builder.mutation<AuthResponse, RegistrationPayload>({
      query: (payload: RegistrationPayload) => ({
        url: "/auth/register",
        method: "POST",
        body: payload,
      }),
    }),
    loginWithCode: builder.mutation<Token, AuthByCodeRequest>({
      query: ({ email, code }) => ({
        url: "/users/v2/login-with-code",
        method: "POST",
        body: { email, code },
      }),
    }),
    loginWithPassword: builder.mutation<SignInResponse, AuthByPasswordRequest>({
      query: ({ email, password }) => ({
        url: "/users/v2/sign-in",
        method: "POST",
        body: { email, password },
      }),
      invalidatesTags: ["UserEvents"],
    }),
    forgetPassword: builder.mutation<ForgetPasswordResponse, string>({
      query: (email: string) => ({
        url: "/users/v2/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<string, ResetPasswordRequest>({
      query: (request: ResetPasswordRequest) => ({
        url: "/users/v2/reset-password",
        method: "PATCH",
        body: request,
      }),
    }),
    oldResetPassword: builder.mutation<AuthResponse, ResetPasswordRequestOld>({
      query: (request: ResetPasswordRequestOld) => ({
        url: "/auth/reset-password",
        method: "POST",
        body: request,
      }),
    }),
    logout: builder.mutation<string, void>({
      query: () => ({
        url: "/health",
        method: "GET",
      }),
    }),
    getGoogleAuthUrl: builder.query<GoogleAuthUrlResponse, void>({
      query: () => "/auth/google/authorize",
    }),
    loginWithGoogle: builder.mutation<AuthResponse, URLSearchParams>({
      query: (params: URLSearchParams) => ({
        url: `/auth/google/callback?${params}`,
        method: "POST",
      }),
      invalidatesTags: ["UserEvents"],
    }),
    verifySignUpEmail: builder.mutation<
      VerifySignUpEmailResponse,
      VerifyEmailRequest
    >({
      query: (request) => ({
        url: "/users/v2/sign-up",
        method: "POST",
        body: request,
      }),
    }),

    createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: (request) => ({
        url: "/users/v2/register",
        method: "POST",
        body: request,
      }),
    }),
    requestLoginCode: builder.mutation<any, VerifyEmailRequest>({
      query: (request) => ({
        url: "/users/v2/request-code",
        method: "POST",
        body: request,
      }),
    }),
    validateCode: builder.mutation<RequestCodeResponse, RequestCodeRequest>({
      query: (request) => ({
        url: "/users/v2/check-user-by-auth-code",
        method: "POST",
        body: request,
      }),
    }),
    validatePasswordRecoveryCode: builder.mutation<
      string,
      PasswordRecoveryCodeRequest
    >({
      query: (request) => ({
        url: "/users/v2/verify-reset-password-code",
        method: "POST",
        body: request,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateUserMutation,
  useRegisterMutation,
  useLoginWithCodeMutation,
  useLoginWithPasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useOldResetPasswordMutation,
  useLogoutMutation,
  useLazyGetGoogleAuthUrlQuery,
  useLoginWithGoogleMutation,
  useRequestCodeMutation,
  useRequestLoginCodeMutation,
  useValidatePasswordRecoveryCodeMutation,
  useValidateCodeMutation,
  useVerifySignUpEmailMutation,
} = authApi;
