import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useStoreHooks";
import {
  refreshTokens,
  validateAccessToken,
} from "../../authorization/service/helpers";
import { setUserInfo } from "../../store/User/slice";
import { LoaderWrap } from "../LoaderWrap/LoaderWrap";
import { useLogout } from "../../authorization/hooks/useLogout";
import { LS_ACCESS_TOKEN_KEY } from "../../authorization/helpers/tokens";
import { useAmIActualUser } from "../../hooks/useAmIActualUser";
import { SessionTerminatedModal } from "../../close-part/features/Modals/SessionTerminatedModal";

export const ClosePartWrapper = ({
  children,
}: {
  children?: React.ReactElement | React.ReactNode;
}) => {
  const { onLogOut } = useLogout("/login");

  const dispatch = useAppDispatch();

  const [isRefreshingTokens, setIsRefreshingTokens] = useState(false);
  const [showSessionTerminatedModal, setShowSessionTerminatedModal] =
    useState(false);
  const [redirected, setRedirected] = useState(false);

  const { startFetch: checkCurrentUser } = useAmIActualUser();

  const handleRefreshTokens = useCallback(() => {
    refreshTokens(
      () => {
        // no refreshToken or failed http request
        dispatch(setUserInfo(null));
        setRedirected(true);
        setIsRefreshingTokens(false);
        onLogOut();
      },
      () => setIsRefreshingTokens(false),
    );
  }, [dispatch, onLogOut]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isRefreshingTokens || redirected) return;

    const token = localStorage.getItem("access-token");
    if (validateAccessToken(token)) return;

    setIsRefreshingTokens(true);
    handleRefreshTokens();
  });

  useEffect(() => {
    if (!redirected) return;
    handleRefreshTokens();
  }, [handleRefreshTokens, redirected]);

  // Here we check if the access token in Local Storage belongs to the current user.
  useEffect(() => {
    const tokenListener = async (event: StorageEvent) => {
      if (event.key === LS_ACCESS_TOKEN_KEY && event.oldValue !== event.newValue) {
        const result = await checkCurrentUser();
        if (!result) setShowSessionTerminatedModal(true);
      }
    };

    window.addEventListener("storage", tokenListener);

    return () => window.removeEventListener("storage", tokenListener);
  }, []);

  if (isRefreshingTokens || redirected) return <LoaderWrap />;

  return (
    <>
      {children || <Outlet />}
      {showSessionTerminatedModal && <SessionTerminatedModal />}
    </>
  );
};
