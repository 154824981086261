import { Nullable } from "src/shared/utils";
import { ChatDTO, ChatMemberDTO, ChatMessageDTO } from "./data.types";

export type GetChatListResp = {
  dialogs: ChatDTO[];
  last_evaluated_key: unknown;
};

export type GetChatInfoQuery = {
  userId?: string;
  chatId?: string;
  lastEvaluatedKey?: unknown;
};

export type GetChatListQuery = {
  lastEvaluatedKey: unknown;
};

export type GetMessageListQuery = {
  chatId: string;
  lastEvaluatedKey: unknown;
  reverse?: boolean;
};

export type GetMessageListResp = {
  messages: ChatMessageDTO[];
  last_evaluated_key: unknown;
};

export type GetInitialMessegesResp = {
  last_read_message: Nullable<ChatMessageDTO>;
  newest: Nullable<GetMessageListResp>;
  oldest: Nullable<GetMessageListResp>;
};

export type GetChatMembersResp = {
  members: ChatMemberDTO[];
};

export type GetChatMembersQuery = { chatId: string };

export type DeleteChatQuery = { chatId: string };

export type GetMessagesHistoryQuery = { chatId: string };

export enum MessageSendingStatus {
  SENDING = "sending",
  SENT = "sent",
  FAILED = "failed",
}

export interface ChatMessageDTOExtended extends ChatMessageDTO {
  isUnread?: boolean;
  sendingStatus?: MessageSendingStatus;
}

export type SocketEventUser = {
  user_id: string;
  data: {
    user_avatar: string;
    name: string;
  };
};

export type NewMessageSocketEvent = {
  message: ChatMessageDTO;
  user: Nullable<SocketEventUser>;
};

export type SendNewMessageData = {
  message: string;
  identifier: string;
  chat_id: string;
};

export type GetMessageListTransformedRes = {
  data: ChatMessageDTO[];
  lastEvaluatedKey: unknown;
};

export type GetChatListTransformedRes = {
  data: ChatDTO[];
  lastEvaluatedKey: unknown;
};

export type GetMessagesHistoryTransformedRes = {
  data: ChatMessageDTOExtended[];
  lastEvaluatedKey: unknown;
};

export type GetChatAuthTokenRes = {
  token: string;
  refresh_token: string;
};
