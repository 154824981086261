import { ChatMessageDTOExtended, NewMessageSocketEvent } from "../types";

export const normalizeMessage = (
  socketEventData: NewMessageSocketEvent,
): ChatMessageDTOExtended => ({
  id: socketEventData.message.id,
  chat_id: socketEventData.message.chat_id,
  datetime: socketEventData.message.datetime,
  system_metadata: socketEventData.message.system_metadata,
  text: socketEventData.message.text,
  type: socketEventData.message.type,
  user: socketEventData.user
    ? { id: socketEventData.user.user_id, data: socketEventData.user.data }
    : null,
  isUnread: true,
});
