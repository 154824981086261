// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { appApi } from "../../api/api";
import {
  ChangeEmailResponse,
  CurrentUser,
  IPassword,
  IUpdatePassword,
  PatchCurrentUser,
  PrivacySettings,
  UploadImageKey,
  GetUserResponse,
  UserAnalyticsResponse,
  SetPasswordPayload,
} from "./types";
import { PageResponse } from "../store";
import { addToast } from "../toast/slice";
import { DEFAULT_NOTIFICATION_ESTIMATE } from "../../constants";

export const currentUserApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => "/users/me",
      providesTags: ["UserProfile"],
    }),
    getUser: builder.query<GetUserResponse, { userId?: string; username?: string }>({
      query: ({ userId, username }) => ({
        url: "/users",
        params: {
          unique_id: userId,
          username,
        },
      }),
      providesTags: (profile) => [
        { type: "UserProfile", id: profile?.user.unique_id },
      ],
    }),
    findUsersByUsername: builder.query<PageResponse<CurrentUser>, string>({
      query: (username: string) => ({
        url: "/users/find",
        params: { username, page: 1, size: 50 },
      }),
    }),

    followOrUnfollowUser: builder.mutation<
      GetUserResponse,
      {
        doIFollowUser: boolean;
        userId: string;
        myUserId?: string;
      }
    >({
      query: ({ userId, doIFollowUser }) =>
        doIFollowUser ? `/users/${userId}/unfollow` : `/users/${userId}/follow`,
      invalidatesTags: (response, error, args) => [
        { type: "UserProfile", id: args.userId },
        { type: "UserProfile", id: args.myUserId },
      ],
      async onQueryStarted(requestArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // [PROD-3118] Update button Follow <-> Unfollow in Followers modal after un/follow in other place
          const updateUserInCachedFollowersList = (
            userId: string,
            newDoIFollowUser: boolean,
          ) =>
            currentUserApi.util.updateQueryData(
              "getFollowers",
              undefined,
              (currentFollowers) => {
                if (!currentFollowers) return currentFollowers;

                return currentFollowers.map((follower) =>
                  follower.user.unique_id === userId
                    ? {
                        ...follower,
                        is_following: newDoIFollowUser,
                      }
                    : follower,
                );
              },
            );
          dispatch(
            updateUserInCachedFollowersList(
              requestArg.userId,
              !requestArg.doIFollowUser,
            ),
          );
        } catch (err) {
          dispatch(
            addToast({
              type: "error",
              content: "Something went wrong. Please try later.",
              estimate: DEFAULT_NOTIFICATION_ESTIMATE,
            }),
          );
        }
      },
    }),
    getFollowers: builder.query<GetUserResponse[], void>({
      query: () => ({
        url: "/users/followers",
      }),
    }),
    getFollowings: builder.query<GetUserResponse[], void>({
      query: () => ({
        url: "/users/followings",
      }),
    }),

    // TODO: replace queries by mutation to use tag invalidation for auto profile update
    // ===>
    followUser: builder.query<{ status: boolean }, { uniqueId: string }>({
      query: ({ uniqueId }) => `/users/${uniqueId}/follow`,
    }),
    unfollowUser: builder.query<{ status: boolean }, { uniqueId: string }>({
      query: ({ uniqueId }) => `/users/${uniqueId}/unfollow`,
    }),
    // <===
    patchCurrentUser: builder.mutation<CurrentUser, PatchCurrentUser>({
      query: (userInfo: PatchCurrentUser) => ({
        url: "/users/me",
        method: "PATCH",
        body: userInfo,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    uploadAvatar: builder.mutation<string | null, UploadImageKey>({
      query: (key: UploadImageKey) => ({
        url: "/users/me/upload-avatar",
        method: "POST",
        body: key,
      }),
    }),
    getDefaultAvatar: builder.query<{ url: string }, void>({
      query: () => "/users/me/default-avatar",
    }),
    getPrivacySettings: builder.query<PrivacySettings, void>({
      query: () => ({
        url: "/users/me/privacy-settings",
      }),
    }),
    patchPrivacySettings: builder.mutation<PrivacySettings, PrivacySettings>({
      query: (data: PrivacySettings) => ({
        url: "/users/me/privacy-settings",
        method: "PATCH",
        body: data,
      }),
    }),
    setPassword: builder.mutation<string, IPassword>({
      query: (data: IPassword) => ({
        url: "/users/me/set-password",
        method: "POST",
        body: data,
      }),
    }),
    setPasswordV2: builder.mutation<string, SetPasswordPayload>({
      query: (data: SetPasswordPayload) => {
        const headers: Record<string, string> = {};

        if (data.token) headers.Authorization = `Bearer ${data.token.access_token}`;

        return {
          url: "/users/v2/me/set-password",
          method: "POST",
          headers,
          body: data.password,
        };
      },
    }),
    updatePassword: builder.mutation<PrivacySettings, IUpdatePassword>({
      query: (data: IUpdatePassword) => ({
        url: "/users/me/update-password",
        method: "POST",
        body: data,
      }),
    }),
    deletePassword: builder.mutation<string, IPassword>({
      query: (data: IPassword) => ({
        url: "/users/me/delete-password",
        method: "POST",
        body: data,
      }),
    }),
    requestCodeChangeEmail: builder.mutation<ChangeEmailResponse, void>({
      query: () => ({
        url: "/users/me/change-email/request-code",
        method: "POST",
      }),
    }),
    validateCodeChangeEmail: builder.mutation<ChangeEmailResponse, { code: string }>(
      {
        query: (data: { code: string }) => ({
          url: "/users/me/change-email/validate-code",
          method: "POST",
          body: data,
        }),
      },
    ),
    requestNewEmailCode: builder.mutation<
      ChangeEmailResponse,
      { new_email: string }
    >({
      query: (data: { new_email: string }) => ({
        url: "/users/me/change-email/request-new-email-code",
        method: "POST",
        body: data,
      }),
    }),
    validateNewEmailCode: builder.mutation<ChangeEmailResponse, { code: string }>({
      query: (data: { code: string }) => ({
        url: "/users/me/change-email/validate-new-email-code",
        method: "POST",
        body: data,
      }),
    }),
    deleteAccount: builder.mutation<{ status: string }, void>({
      query: () => ({
        url: "/users/me/delete-account",
        method: "POST",
      }),
    }),
    getUserAnalytics: builder.query<UserAnalyticsResponse, void>({
      query: () => ({
        url: "/users/me/analytics",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCurrentUserQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyFindUsersByUsernameQuery,
  useLazyFollowUserQuery,
  useLazyUnfollowUserQuery,
  useLazyGetCurrentUserQuery,
  usePatchCurrentUserMutation,
  useUploadAvatarMutation,
  useGetPrivacySettingsQuery,
  usePatchPrivacySettingsMutation,
  useSetPasswordMutation,
  useSetPasswordV2Mutation,
  useDeletePasswordMutation,
  useUpdatePasswordMutation,
  useRequestCodeChangeEmailMutation,
  useValidateCodeChangeEmailMutation,
  useRequestNewEmailCodeMutation,
  useValidateNewEmailCodeMutation,
  useDeleteAccountMutation,
  useGetDefaultAvatarQuery,
  useGetUserAnalyticsQuery,
  useLazyGetUserAnalyticsQuery,
  useFollowOrUnfollowUserMutation,
  useGetFollowersQuery,
  useGetFollowingsQuery,
  endpoints: userApiEndpoints,
} = currentUserApi;
