import { Button } from "@evvve/ui-kit";
import ModalTemplate from "../ModalTemplate";
import style from "./style.module.scss";

/**
 * Modal window for warning a user about wrong session data.
 * It happens when user logins to different account in two different tabs.
 */

export const SessionTerminatedModalButtons = () => {
  const handleClick = () => window.location.reload();

  return <Button onClick={handleClick}>Switch</Button>;
};

export const SessionTerminatedModal = () => (
  <ModalTemplate
    className={style.modal}
    isOpen
    onClose={() => {}}
    closable={false}
    title="Session terminated"
    description="You have logged into another account. The current session has terminated. To continue working in this tab, please confirm the account switch."
    buttons={<SessionTerminatedModalButtons />}
  />
);
